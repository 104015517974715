import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { LocaleContext } from "context/locale-context";
import { useStaticQuery, graphql } from "gatsby";
import useTranslations from "components/hooks/useTranslations";

function SEO({ metadata }) {
  const locale = useContext(LocaleContext);
  const { description, title, image } = metadata;
  const tr = useTranslations();

  const { site, imageSharp } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            twitter
          }
        }
        imageSharp(original: { src: { regex: "/cover/" } }) {
          fluid {
            src
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const mainImage = imageSharp.fluid.src;
  const socialMediaImage =
    site.siteMetadata.siteUrl +
    (image ? image.childImageSharp.fluid.src : mainImage);

  return (
    <Helmet
      htmlAttributes={{
        locale
      }}
      title={title}
      titleTemplate={`%s | ${tr(site.siteMetadata.title)}`}
      meta={[
        {
          name: `description`,
          content: tr(metaDescription)
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: tr(metaDescription)
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: socialMediaImage
        },
        {
          property: `twitter:image`,
          content: socialMediaImage
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: title
        },
        {
          name: `twitter:title`,
          content: tr(title)
        },
        {
          name: `twitter:description`,
          content: tr(metaDescription)
        }
      ]}
    />
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
